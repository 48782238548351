<template>
  <default>
    <div>
       <v-chip-group mandatory active-class=" primary">
            <v-chip :to="{ name: 'Dashboard' }">Dashboard
            </v-chip>
        </v-chip-group>
    </div>
    <div class="tw-flex tw-justify-end tw-py-2 md:tw-py-5">
      <v-icon>mdi-reload</v-icon>
    </div>
    <v-card elevation="2" class="tw-px-2 md:tw-px-6 tw-pb-4 md:tw-pb-6">
      <card-title icon="/images/icons/top-icon.png">Dashboard Overview</card-title>
      <div v-if="!loading" class="tw-w-full px-2 md:px-4 tw-py-4 md:tw-py-10 tw-grid tw-grid-cols-1 md:tw-grid-cols-4 tw-gap-2 md:tw-gap-6">
        <v-card v-for="(i, $index) of items" :key="$index" elevation="2" class="tw-px-2 md:tw-px-6 tw-mt-4 md:tw-mt-0">
          <card-title icon="/images/icons/list-star.png" />
          <div class="tw-w-full tw-flex-col tw-flex tw-justify-end tw-pb-4 md:tw-pb-10">
            <p class="c-gray-text tw-text-sm tw-text-right">{{ i.agentStatName }}</p>
            <p class="c-gray-text tw-text-base md:tw-text-xl tw-text-right">{{ i.agentStatValue }}</p>
          </div>
          <hr class="tw-pb-2 md:tw-pb-4">
        </v-card>
      </div>
      <div v-else class="tw-w-full tw-flex tw-justify-center">
        <v-progress-circular
          :size="50"
          color="primary"
          indeterminate
        ></v-progress-circular>
      </div>
    </v-card>
    <saved-quotes />
  </default>
</template>

<script>
import axios from 'axios';
import { mapState } from 'vuex';
import Default from '../layouts/Default';
import CardTitle from '../components/CardTitle';
import SavedQuotes from '../components/dashboard/SavedQuotes';

export default {
  name: 'Dashboard',
  components: { SavedQuotes, CardTitle, Default },
  metaInfo: {
    title: 'Dashboard',
  },
  data() {
    return {
      items: [],
      loading: true,
      currentTab: SavedQuotes,
    };
  },
  computed: {
    ...mapState({
      agentCode: (state) => state.auth.userInfo.agnCode,
    }),
  },
  mounted() {
    const formData = this.agentCode;
    axios.get(`/lmsagent/quotations/findAgentQuotesStats?agent Code=${formData}`)
      .then((res) => {
        this.items = res.data.data;
        this.loading = false;
      });
  },
};
</script>
